import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
            <Helmet>
        <meta charSet="utf-8" />
        <title>Thematic Saskatchewan</title>
        <meta name="description" content="Election Cartograms" />
        <meta name="image" content="https://awmcphee.ca/cartogram.png" />
        <meta property="og:image" content="https://awmcphee.ca/cartogram.png"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="Election Cartograms"/>
        <meta name="twitter:description" content="Because land doesn't vote."/>
        <meta name="twitter:image" content="https://awmcphee.ca/cartogram.png"/>
      </Helmet>
      <h1>Thematic Saskatchewan</h1>
      <h2>Hard to Spell, Easy to Draw</h2>
      <p>Flex your Saskatchewan connection with this unique 12” x 18” map series (all printed on sturdy cardstock). New products available whenever I come up with an idea for them.</p>
      <div className="portfolio">
        <div className="pf">
          <StaticImage
            src="../portfolio/rural_municipalities.jpg"
            alt="A map of the rural municipalities of Saskatchewan."
          />
          <h4>Rural Municipalities of Saskatchewan</h4>
          <span><em>This is the only commercial map featuring the names of every R.M. Essential for the office.</em></span>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/saskatchewan_wmz.jpg"
            alt="A map of Wildlife Management Zones in Saskatchewan."
          />
          <h4>Wildlife Management Zones of Saskatchewan</h4>
          <span><em>For the hunters and outdoorspeople in your life.</em></span>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/swift_lidar.jpg"
            alt="A 3D map of Swift Current."
          />
          <h4>Swift Current (1m LiDAR)</h4>
          <span><em>A 3D render of Swift Current city limits. This super-high-resolution data was collected for flood mitigation.</em></span>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/hutterites.jpg"
            alt="A map of Hutterite colonies in Saskatchewan."
          />
          <h4>Saskatchewan's Hutterite Colonies</h4>
          <span><em>Updated monthly. Includes clan affiliation and lineage.</em></span>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/fransaskois.jpg"
            alt="A map of Saskatchewan's Francophone population."
          />
          <h4>The Fransaskois World</h4>
          <span><em>The Francophone population of each community in the province.</em></span>
        </div>
      </div>
      <p>My web store is being overhauled - these will be available for sale soon! I also travel to farmers markets around the province.</p>
    </Layout>
  );
}
